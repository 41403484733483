import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import fetchDataFromDynamoDB from './DynamoDBService';
import { Button, Card, Typography, Grid, CircularProgress, 
    Table, TableBody, TableCell, TableContainer, 
    TableHead, TableRow, Paper, Alert, Box } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import logo from '../../assets/logoNew2.png';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { Amplify } from 'aws-amplify';
import awsExports from '../../aws-exports';
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { signOut } from 'aws-amplify/auth';

Amplify.configure(awsExports);

const theme = {
  name: 'custom-theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          10: '#232F3E',
          80: '#FF9900',
          90: '#FFA726',
          100: '#FF9900',
        },
      },
      background: {
        primary: '#F7F7F7',
        secondary: '#FFFFFF',
      },
      font: {
        interactive: '#232F3E',
      },
    },
    components: {
      button: {
        primary: {
          backgroundColor: '#232F3E',
          _hover: {
            backgroundColor: '#1D2731',
          },
        },
      },
      heading: {
        color: '#232F3E',
      },
    },
  },
};


function VehicleOdometerDetail() {
    const [vehicleList, setVehicleList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [lastUpdatedTime, setLastUpdatedTime] = useState('');
    // eslint-disable-next-line
    const [totalDistance, setTotalDistance] = useState(0);
    const [monthlyData, setMonthlyData] = useState([]);
    // eslint-disable-next-line
    const [isFirstWeekOfMonth, setIsFirstWeekOfMonth] = useState(false);
    const navigate = useNavigate();

    // Helper function to format numbers with commas
    const formatNumber = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    // Sign Out function
    const handleSignOut = async () => {
        try {
            await signOut();
            navigate('/lph'); // Navigate to the home page or login page after sign out
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };

    useEffect(() => {
        const fetchVehicles = async () => {
            try {
                const vehicles = await fetchDataFromDynamoDB();
                setVehicleList(vehicles);
                const currentTime = new Date().toLocaleTimeString();
                setLastUpdatedTime(currentTime);

                // Calculate total distance and monthly distances
                let total = 0;
                const monthlyDistances = {};

                vehicles.forEach(vehicle => {
                    if (vehicle.monthlyDistances) {
                        vehicle.monthlyDistances.forEach(monthData => {
                            const distance = parseFloat(monthData.distance);
                            if (!isNaN(distance)) {
                                total += distance;
                                if (monthlyDistances[monthData.month]) {
                                    monthlyDistances[monthData.month] += distance;
                                } else {
                                    monthlyDistances[monthData.month] = distance;
                                }
                            }
                        });
                    }
                });

                setTotalDistance(total);

                // Convert monthly distances to the required format
                const formattedMonthlyData = Object.entries(monthlyDistances).map(([month, distance]) => ({
                    month,
                    distance,
                    cost: distance * 2 // Assuming cost is 2 KES per km
                }));

                setMonthlyData(formattedMonthlyData);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchVehicles();

        // Check if it's the first week of the month
        const now = new Date();
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const daysSinceFirstOfMonth = Math.floor((now - firstDayOfMonth) / (24 * 60 * 60 * 1000));
        setIsFirstWeekOfMonth(daysSinceFirstOfMonth < 7);
    }, []);

    function generateInvoiceNumber(month, distance, cost) {
        // Get the month number (1-12)
        const monthNumber = new Date(Date.parse(month + " 1, 2000")).getMonth() + 1;
        
        // Use the last two digits of the year
        const year = new Date().getFullYear() % 100;
        
        // Use the last digit of the distance
        const distanceDigit = Math.floor(distance) % 10;
        
        // Use the last digit of the cost
        const costDigit = Math.floor(cost) % 10;
        
        // Combine all parts
        const invoiceNumber = `${year}${monthNumber.toString().padStart(2, '0')}${distanceDigit}${costDigit}`;
        
        return invoiceNumber;
    }

    const generatePDF = (month, distance, cost) => {
        const doc = new jsPDF();
        const invoiceNumber = generateInvoiceNumber(month, distance, cost);
        const invoiceDate = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
        const dueDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    
        // Define colors
        const primaryColor = [35, 47, 62];
        const secondaryColor = [0, 0, 0];
        const lightGray = [200, 200, 200];
        const darkGray = [100, 100, 100];

        // Helper function to draw a bordered box
        function drawBorderedBox(doc, x, y, width, height, color) {
            doc.setDrawColor(...color);
            doc.rect(x, y, width, height);
        }

        // Add company logo
        doc.addImage(logo, 'PNG', 15, 15, 40, 40);

        // Add invoice details
        const invoiceDetailsX = 120;
        const invoiceDetailsY = 15;
        const invoiceDetailsWidth = 80;
        const invoiceDetailsHeight = 30;

        drawBorderedBox(doc, invoiceDetailsX, invoiceDetailsY, invoiceDetailsWidth, invoiceDetailsHeight, lightGray);

        doc.setFontSize(9);
        doc.setTextColor(...darkGray);
        doc.setFont('helvetica', 'normal');

        // Labels
        doc.text("Invoice Number:", invoiceDetailsX + 5, invoiceDetailsY + 8);
        doc.text("Date Generated:", invoiceDetailsX + 5, invoiceDetailsY + 16);
        doc.text("Due Date:", invoiceDetailsX + 5, invoiceDetailsY + 24);

        // Values
        doc.setTextColor(...primaryColor);
        doc.setFont('helvetica', 'bold');
        doc.text(invoiceNumber, invoiceDetailsX + invoiceDetailsWidth - 5, invoiceDetailsY + 8, null, null, 'right');
        doc.text(invoiceDate, invoiceDetailsX + invoiceDetailsWidth - 5, invoiceDetailsY + 16, null, null, 'right');
        doc.text(dueDate, invoiceDetailsX + invoiceDetailsWidth - 5, invoiceDetailsY + 24, null, null, 'right');

        // Add company information block
        doc.setFontSize(10);
        doc.setTextColor(...primaryColor);
        doc.setFont('helvetica', 'bold');
        doc.text('From:', 15, 65);
        doc.setFont('helvetica', 'normal');
        doc.text('Data - I M', 15, 72);
        doc.text('254 115 566 678', 15, 79);
        doc.text('enquiry@data-im.com', 15, 86);
        doc.text('Karen Village, Nairobi, Kenya', 15, 93);

        // Add client information block
        doc.setFont('helvetica', 'bold');
        doc.text('Bill To:', 120, 65);
        doc.setFont('helvetica', 'normal');
        doc.text('Integrity Academy', 120, 72);
        doc.text('254 721 306 449', 120, 79);
        doc.text('integrity.academy@yahoo.com', 120, 86);
        doc.text('Naivasha, Kenya.', 120, 93);

        // Add "Ship To" section
        doc.setFont('helvetica', 'bold');
        doc.text('Ship To:', 120, 107);
        doc.setFont('helvetica', 'normal');
        doc.text('Naivasha, Kenya.', 120, 114);

        // Add a professional table for invoice details
        doc.autoTable({
            startY: 125,
            head: [['DESCRIPTION', 'PERIOD', 'DISTANCE', 'AMOUNT (KES)']],
            body: [
                [`Billing Period`, `${month}`, '',  ''],
                [`Total Distance`,'', `${formatNumber(distance.toFixed(0))} km`, ''],
                ['Total Cost', '', '', formatNumber(cost.toFixed(0))],
            ],
            theme: 'striped',
            headStyles: { fillColor: primaryColor, textColor: 255 },
            alternateRowStyles: { fillColor: [247, 247, 247] },
            columnStyles: {
                0: { cellWidth: 60 },
                1: { cellWidth: 40 },
                2: { cellWidth: 40 },
                3: { cellWidth: 40 },
            },
        });

        // Add payment instructions block
        const paymentY = doc.autoTable.previous.finalY + 20;
        doc.setFontSize(10);
        doc.setTextColor(...primaryColor);
        doc.setFont('helvetica', 'bold');
        doc.text('Payment Method:', 15, paymentY);
        doc.setFont('helvetica', 'normal');
        doc.text('Safaricom M-PESA Transfer', 15, paymentY + 7);
        doc.text('Paybill No. 400200', 15, paymentY + 14);
        doc.text('Account No. 25411556667801', 15, paymentY + 21);

        // Add totals section
        const totalsY = paymentY;
        doc.setFont('helvetica', 'bold');
        doc.text('Subtotal:', 140, totalsY);
        doc.text('Tax (0%):', 140, totalsY + 7);
        doc.text('Total:', 140, totalsY + 14);
        doc.text('Amount Paid:', 140, totalsY + 21);
        doc.text('Balance Due:', 140, totalsY + 28);

        // Draw underline for Balance Due
        const textWidth = doc.getTextWidth('Balance Due:');
        doc.setDrawColor(...secondaryColor);
        doc.line(140, totalsY + 29, 140 + textWidth, totalsY + 29);

        // Values for the totals
        doc.setFont('helvetica', 'normal');
        doc.text(`${formatNumber(cost.toFixed(0))}.00`, 200, totalsY, null, null, 'right');
        doc.text('0.00', 200, totalsY + 7, null, null, 'right');
        doc.text(`${formatNumber(cost.toFixed(0))}.00`, 200, totalsY + 14, null, null, 'right');
        doc.text('0.00', 200, totalsY + 21, null, null, 'right');
        doc.setFont('helvetica', 'bold');
        doc.text(`KES ${formatNumber(cost.toFixed(0))}.00`, 200, totalsY + 28, null, null, 'right');

        // Add footer section
        const footerY = doc.internal.pageSize.height - 50;
        doc.setFontSize(9);
        doc.setFont('helvetica', 'bold');
        doc.text('Notes:', 15, footerY);
        doc.setFont('helvetica', 'normal');
        doc.text('', 15, footerY + 7);
    
        // Finalize PDF
        doc.save(`${month}_invoice.pdf`);
    };
    
    

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <CircularProgress sx={{ color: '#FF9900' }}/>
            </div>
        );
    }

    if (error) {
        return (
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                minHeight: '100vh',
                bgcolor: 'background.default',
                p: 3
            }}>
                <Paper elevation={3} sx={{ 
                    maxWidth: 400, 
                    width: '100%', 
                    p: 3, 
                    textAlign: 'center'
                }}>
                    <ErrorOutlineIcon sx={{ fontSize: 60, color: 'error.main', mb: 2 }} />
                    <Typography variant="h5" gutterBottom sx={{ color: 'text.primary' }}>
                        Error Loading Vehicles. Check Internet Connection.
                    </Typography>
                    <Alert severity="error" sx={{ mt: 2 }}>
                        {error}
                    </Alert>
                </Paper>
            </Box>
        );
    }

    return (
        <div className="container mt-4">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button 
                    variant="contained"
                    onClick={() => navigate(-1)} 
                    sx={{ 
                        mb: 3, 
                        backgroundColor: '#232F3E', 
                        color: '#FFFFFF', 
                        '&:hover': { backgroundColor: '#1D2731' },
                        fontSize: 'clamp(10px, 1.5vw, 14px)',
                        borderRadius: '10px',
                        padding: '8px 16px',
                        textTransform: 'none',
                    }}
                    // startIcon={<i className="bi bi-arrow-left-circle"></i>}
                >
                    Back
                </Button>
                <Button 
                    variant="contained"
                    onClick={handleSignOut} 
                    sx={{ 
                        mb: 3, 
                        backgroundColor: '#232F3E', 
                        color: '#FFFFFF', 
                        '&:hover': { backgroundColor: '#1D2731' },
                        fontSize: 'clamp(10px, 1.5vw, 14px)',
                        borderRadius: '10px',
                        padding: '8px 16px',
                        textTransform: 'none',
                    }}
                >
                    Sign Out
                </Button>
            </div>

            <Typography 
                variant="h5" 
                sx={{ 
                    color: '#FF9900', 
                    mb: 3, 
                    fontSize: 'clamp(18px, 3vw, 24px)', 
                    fontWeight: 'bold',
                    textAlign: 'center'
                }}
            >
                Registered Vehicles
            </Typography>
            <Grid container spacing={4}>
                {vehicleList.length > 0 ? (
                    vehicleList.map((vehicle, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <Link to={`/vehicle-odometer/${vehicle.deviceId}`} style={{ textDecoration: 'none' }}>
                                <Card sx={{ 
                                    height: '100%', 
                                    boxShadow: 3, 
                                    borderRadius: 2, 
                                    p: 2,
                                    backgroundColor: '#F7F7F7',
                                    '&:hover': {
                                        boxShadow: 6
                                    }
                                }}>
                                    <Typography 
                                        variant="h6" 
                                        sx={{ 
                                            color: '#232F3E', 
                                            fontWeight: 'bold',
                                            mb: 2,
                                            textAlign: 'center',
                                            fontSize: 'clamp(16px, 2vw, 20px)',
                                        }}
                                    >
                                        {vehicle.vehicleRegNumber}
                                    </Typography>
                                    <Typography 
                                        sx={{ 
                                            color: '#333333', 
                                            textAlign: 'center',
                                            fontSize: 'clamp(12px, 1.5vw, 14px)',
                                        }}
                                    >
                                        Total Distance Covered: {formatNumber(vehicle.totalDistanceCovered.toFixed(0))} km
                                    </Typography>
                                    <Typography 
                                        sx={{ 
                                            color: '#666666', 
                                            textAlign: 'center',
                                            fontSize: 'clamp(10px, 1.2vw, 12px)',
                                            mt: 1,
                                        }}
                                    >
                                        Last Updated: {lastUpdatedTime}
                                    </Typography>
                                </Card>
                            </Link>
                        </Grid>
                    ))
                ) : (
                    <Typography sx={{ color: '#333333', textAlign: 'center', width: '100%' }}>
                        No vehicles found.
                    </Typography>
                )}
            </Grid>
            <Typography 
                variant="h6" 
                sx={{ 
                    color: '#FF9900', 
                    mt: 4,
                    mb: 3, 
                    fontSize: 'clamp(18px, 3vw, 24px)', 
                    fontWeight: 'bold',
                    textAlign: 'center'
                }}
            >
                Monthly Records - Distance and Billing.
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ textAlign: 'center' }}>Month</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>Total Distance Covered (km)</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>Total Cost (KES)</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}> Download Invoice </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {monthlyData.map((monthData, index) => { 
                        const now = new Date();
                        const currentMonth = now.toLocaleString('default', { month: 'long' });
                        const currentYear = now.getFullYear();
                        const currentMonthYear = `${currentMonth} ${currentYear}`;

                        // check if it's the first week of the month
                        const isFirstWeekOfMonth = now.getDate() <= 7;
                        
                        const previousMonthCumulative = index > 0 
                            ? monthlyData.slice(0, index).reduce((sum, data) => sum + data.distance, 0) 
                            : 0;
                        const currentMonthCumulative = previousMonthCumulative + monthData.distance;                            

                        const isCurrentMonth = monthData.month === currentMonthYear;

                        return (
                            <TableRow key={index}>
                                <TableCell sx={{ fontWeight: 'bold' }}>{monthData.month}</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: 'clamp(12px, 1.5vw, 14px)' }}>
                                    {formatNumber(previousMonthCumulative.toFixed(0))} - {formatNumber(currentMonthCumulative.toFixed(0))} km
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: 'clamp(12px, 1.5vw, 14px)' }}>
                                    {formatNumber(monthData.cost.toFixed(0))}
                                </TableCell>
                                <TableCell>
                                    {isFirstWeekOfMonth ? (
                                        <Button 
                                        variant="contained"
                                        onClick={() => generatePDF(monthData.month, monthData.distance, monthData.cost)}
                                        sx={{ 
                                            backgroundColor: '#232F3E', 
                                            color: '#FFFFFF', 
                                            '&:hover': { backgroundColor: '#232F3E' },
                                            textTransform: 'none',
                                            borderRadius: '10px',
                                            padding: '8px 16px',
                                            fontSize: 'clamp(10px, 1.5vw, 14px)',
                                            opacity: isCurrentMonth ? 0.7 : 1, // Reduce opacity for current month
                                        }}
                                        disabled={isCurrentMonth} // Disable button for current month
                                        title={isCurrentMonth ? "Invoice not available for current month" : `Download Invoice for ${monthData.month}`}
                                    >
                                        Download Invoice
                                    </Button>
                                    ) : ( 
                                        <Typography variant="body2" sx={{ color: '#666666', textAlign: 'center', fontSize: 'clamp(12px, 1.5vw, 16px)' }}>
                                                Download available in the first week of every month.
                                            </Typography>
                                    )}
                                    
                                </TableCell>
                            </TableRow>
                        );
                    })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default withAuthenticator(VehicleOdometerDetail, {
    theme,
});