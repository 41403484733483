import React from "react";
import { Telephone, GeoAlt, Clock, Envelope } from 'react-bootstrap-icons';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import './Contacts.module.css';

function Career() {
    const navigate = useNavigate();

    return (
        <div className="container mt-4">
            <Button 
                variant="contained"
                onClick={() => navigate(-1)} 
                className="backButton"
                startIcon={<i className="bi bi-arrow-left-circle"></i>}
            >
                Back
            </Button>
            
            

            {/* Combined career Section */}
            <div className="career-section">
                <h4 className="section-heading">
                    <Telephone size={24} className="icon" /> Phone
                </h4>
                <ul className="career-list">
                    {/* <li>+254 (0)788 048 240</li> */}
                    <li>254 115 566 678</li>
                </ul>

                <h4 className="section-heading">
                    <GeoAlt size={24} className="icon" /> Address
                </h4>
                <p>Karen Village, Nairobi, Kenya</p>

                <h4 className="section-heading">
                    <Envelope size={24} className="icon" /> Email
                </h4>
                <p><a href="mailto:enquiry@data-im.com" className="career-link">enquiry@data-im.com</a></p>
                {/* <p><a href="mailto:machio@data-im.com" className="career-link">machio@data-im.com</a></p> */}

                {/* Office Hours Section */}
                <h4 className="section-heading">
                <Clock size={24} className="icon" /> Office Hours
                </h4>
                <div className="office-hours">
                    <div className="office-hour-item">
                        <span className="day">Monday - Friday</span>
                        <span className="time">09:00 - 18:00</span>
                    </div>
                    <div className="office-hour-item">
                        <span className="day">Saturday</span>
                        
                        <span className="time">09:00 - 14:00</span>
                    </div>
                    <div className="office-hour-item closed">
                        <span className="day">Sunday</span>
                        <span className="time">Closed</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Career;